import React from 'react';

const NerdImg = ({
  item
}) => {
  return (
    <img
      className="users-rated-us__line"
      src={require(`./img/${item.img}.jpg`)}
      srcSet={`
            ${require(`./img/${item.img}@3x.jpg`)} 3x,
            ${require(`./img/${item.img}@2x.jpg`)} 2x,
            ${require(`./img/${item.img}.jpg`)} 1x
          `}
      alt={item.name}
    />
  )
}

export {
  NerdImg
}
