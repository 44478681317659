let satellite = {};

if (process.env.REACT_APP_BUILD_ID === 'nerdifyit') {
  satellite = require('./nerdifyit');
}

if (process.env.REACT_APP_BUILD_ID === 'nerdify-it') {
  satellite = require('./nerdify-it');
}

if (process.env.REACT_APP_BUILD_ID === 'thenerdify') {
  satellite = require('./thenerdify');
}

if (process.env.REACT_APP_BUILD_ID === 'nerdify-design') {
  satellite = require('./nerdify-design');
}

if (process.env.REACT_APP_BUILD_ID === 'gonerdify-net') {
  satellite = require('./gonerdify-net');
}

if (process.env.REACT_APP_BUILD_ID === 'go-nerdify-com') {
  satellite = require('./go-nerdify-com');
}

export default satellite;
