import React from 'react';

import Info from '../../../../base_components/Info';

import './index.css';

export const Info1 = (props) => {
  return <Info {...props} className={'info_1'} />
};

export const Info2 = (props) => {
  return <Info {...props} className={'info_2'} />
};

export const Info3 = (props) => {
  return <Info {...props} className={'info_3'} />
};

export const Info4 = (props) => {
  return <Info {...props} className={'info_4'} />
};

export const Info5 = (props) => {
  return <Info {...props} className={'info_5'} />
};

export const Info6 = (props) => {
  return <Info {...props} className={'info_6'} />
};

export const Info7 = (props) => {
  return <Info {...props} className={'info_7'} />
};

export const InfoImg = (props) => {
  const { img } = props;

  return (
    <img
      src={require(`./img/${img}@1x.jpg`)}
      srcSet={`
            ${require(`./img/${img}@3x.jpg`)} 3x,
            ${require(`./img/${img}@2x.jpg`)} 2x,
            ${require(`./img/${img}@1x.jpg`)} 1x
          `}
      alt=""
    />
  )
};
