import React, { Component } from 'react';
import classnames from 'classnames';
import Text from '../../../../base_components/Text';

import './index.css';
import subjects from './subjects.json';
import Row from './Row';

const shuffleArray = (array) => {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};

class DemoSubjects extends Component {
  constructor(props) {
    super(props);

    this.oneRow = [
      shuffleArray(subjects.oneRow),
    ];
    this.rows = [
      shuffleArray(subjects.deskRow1),
      shuffleArray(subjects.deskRow2),
    ];
    this.rowsMobile = [
      shuffleArray(subjects.mobRow1),
      shuffleArray(subjects.mobRow2),
      shuffleArray(subjects.mobRow3),
    ];

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { mounted } = this.state;
    const { title, oneRow, theme } = this.props;

    const className = classnames('demoSubjects',this.props.className, {[`demoSubjects_${theme}`]: theme});

    {classnames('top-nerds', {
      [`top-nerds_${theme}`]: theme
    })}

    let list;
    if (oneRow ) {
      list = <div
        className={classnames('demoSubjects__list', {
          demoSubjects__list_mounted: mounted,
        })}
      >
        {this.oneRow.map((row, idx) => (
          <Row items={row} key={idx}/>
        ))}
      </div>

    } else {
      list = <div
        className={classnames('demoSubjects__list', {
          demoSubjects__list_mounted: mounted,
        })}
      >
        {this.rows.map((row, idx) => (
          <Row items={row} key={idx} desktop />
        ))}
        {this.rowsMobile.map((row, idx) => (
          <Row items={row} key={idx} mobile />
        ))}
      </div>
    }

    return (
      <div className={className}>
        <div className="demoSubjects__title">
          {title || (
            <Text>
              Our Nerds graduated in <br className="mobile-only" />
              <span className="strong">75+ different subjects...</span>
            </Text>
          )}
        </div>
        {list}
      </div>
    );
  }
}

export default DemoSubjects;
