import React from 'react';
//import LazyLoad from 'react-lazyload';
//import savetimeIcons from './img/icons.svg';
//import Icons from './../../../../base_components/Icons';
//import Text from '../../../../base_components/Text'

import './savetime.css';
import classnames from "classnames";

const SaveTimeIconImg = ({img, className, ...rest}) => <img className={className} src={require(`./img/${img}`)} alt={''}/>;

export default ({ className, children }) => {
  return <div className={classnames(className)}>
    {children}
  </div>
}

export {
  SaveTimeIconImg
}
/*
export default (props) => {
  return <div className='save-time-block'>
    <div className="holder">
      <div className="h3 save-time-title">Save Time and Effort<br className="mobile-only"/> with Nerdify</div>
      <div className="save-time-wrapper">

        <ul className="save-time-list">
          <li className="list-title">Spend less time...</li>
          <li className="list-item">
            <LazyLoad height={20}>
              <img className="list-item-img" src={require(`./img/red-cross.svg`)} alt={''} />
            </LazyLoad>
            <div className="list-item-msg">{props.leftItem1 || <Text>No confusing forms to fill in</Text>}</div>
          </li>
          <li className="list-item">
            <LazyLoad height={20}>
              <img className="list-item-img" src={require(`./img/red-cross.svg`)} alt={''} />
            </LazyLoad>
            <div className="list-item-msg">{props.leftItem2 || <Text>No lengthy search for right freelancer</Text>}</div>
          </li>
          <li className="list-item">
            <LazyLoad height={20}>
              <img className="list-item-img" src={require(`./img/red-cross.svg`)} alt={''} />
            </LazyLoad>
            <div className="list-item-msg">{props.leftItem3 || <Text>No stressful price negotiations</Text>}</div>
          </li>
        </ul>

        <ul className="save-time-list">
          <li className="list-title">…get better results</li>
          <li className="list-item">
            <LazyLoad height={20}>
              <img className="list-item-img" src={require(`./img/green-check.svg`)} alt={''} />
            </LazyLoad>
            <div className="list-item-msg">{props.rightItem1 || <Text>Instant reply to any request</Text>}</div>
          </li>
          <li className="list-item">
            <LazyLoad height={20}>
              <img className="list-item-img" src={require(`./img/green-check.svg`)} alt={''} />
            </LazyLoad>
            <div className="list-item-msg">{props.rightItem2 || <Text>Just drop photo, video or text with your task</Text>}</div>
          </li>
          <li className="list-item">
            <LazyLoad height={20}>
              <img className="list-item-img" src={require(`./img/green-check.svg`)} alt={''} />
            </LazyLoad>
            <div className="list-item-msg">{props.rightItem3 || <Text>Live updates via SMS/Messenger for free</Text>}</div>
          </li>
        </ul>

      </div>
    </div>
  </div>
}
*/


