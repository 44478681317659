import React from 'react'

import './input-error.css';

const InputError = ({ children, ...rest }) => {
  return (
    <div className="input-error-text">{ children }</div>
  )
}

export default InputError;