import React from 'react';
import Icon from './../Icons';
import classnames from 'classnames';

import Accordion, {
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon as AccordionIconBase,
  AccordionItemHolder
} from '../../../../base_components/Accordion';

import './index.css';

const FaqTheme = (props) => (
  <div className={classnames('faq', props.className)}>
    {props.children}
  </div>
);

const Faq = (props) => <FaqTheme className="faq_1" {...props}/>
const Faq2 = (props) =>  <FaqTheme className="faq_2" {...props}/>
const Faq3 = (props) =>  <FaqTheme className="faq_3" {...props}/>
const Faq4 = (props) =>  <FaqTheme className="faq_4" {...props}/>

export const AccordionIcon = ({ icons, ...rest }) => <AccordionIconBase {...rest}>
  {icons.map((item, index) => {
    return (
      <Icon
        className={classnames('svg-icon', index ? "minus-icon" : "plus-icon")}
        iconName={`${item}`}
        key={`accordion-icon-${item}`}
      />
    )
  })}
</AccordionIconBase>;

  export {
    Faq as default,
    Faq2,
    Faq3,
    Faq4,
    Accordion,
    AccordionHead,
    AccordionBody,
    AccordionItem,
    AccordionItemHolder
  }
