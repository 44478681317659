import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
//import registerServiceWorker from './registerServiceWorker'
import App from "./App";
import "./utils/polyfills";
import { IS_DEV, REACT_APP_ENV } from "./config";
import store from "./store";
import history from "./history";

import { init } from "@sentry/browser";

if (!IS_DEV) {
  init({
    dsn: "https://4e79eb2ce1184f249f1561abc996b6b2@sentry.io/1446658",
    environment: REACT_APP_ENV,
  });
}

const render = IS_DEV ? ReactDOM.render : ReactDOM.hydrate;

render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

//registerServiceWorker()
