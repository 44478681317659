import React from 'react';

import './verified-purchase.css';
import imgIcon from './img/verified-check.png';

const VerifiedPurchase = () => (
  <div className="verifiedPurchase">
    <img src={imgIcon} className="imgIcon" alt="" />
    <p className="verifiedPurchaseText">Verified purchase</p>
  </div>
);

export default VerifiedPurchase;
