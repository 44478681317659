import React, { Component } from 'react';
import classnames from 'classnames';

const SPEED = 75; // px/s

const Item = ({ name }) => <div className="demoItem">{name}</div>;

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {
      duration: 0,
      mounted: false,
    };
  }

  componentDidMount() {
    const length = this.rowRef.offsetWidth;
    const duration = length / SPEED;
    this.setState({ duration, mounted: true });
  }

  render() {
    const { items, mobile, desktop } = this.props;
    const { duration, mounted } = this.state;
    const className = classnames('demoRow', { mounted }, { mobile }, { desktop });
    const style = !duration ? {} : { animationDuration: `${duration}s` };
    return (
      <div className={className} ref={(ref) => { this.rowRef = ref; }} style={style}>
        {items.map(item => <Item key={item} name={item} />)}
        {items.map(item => <Item key={item} name={item} />)}
      </div>
    );
  }
}

export default Row;
