import React from 'react'
import Icon from './../Icons';
import { HnavMob } from '../../../../base_components/HnavMob';
import classnames from 'classnames';
import {
  CSSTransition
} from 'react-transition-group'

import './index.css';
import {NavLink} from "react-router-dom";

export const HnavMob1Theme = HnavMob(({className, isOpen, children, ...rest}) => {
  return (
    <div className={classnames(className, 'hnav-mob_1')}>
      <div className="hnav__overlay"  onClick={rest.onClickClose} />

      <div className="open-btn" onClick={rest.onClickOpen}>
        <Icon iconName='hamburger' />
      </div>

      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="hnav-mob-transition"
        unmountOnExit
        >
        {children(rest)}
      </CSSTransition>
    </div>
  )
});


export const HnavMob2Theme = HnavMob(({className, isOpen, children, ...rest}) => {

  return (
    <div className={classnames(className, 'hnav-mob_2')}>
      <div className="hnav__overlay"  onClick={rest.onClickClose} />

      <div className="open-btn" onClick={rest.onClickOpen}>
        <Icon iconName='hamburger' />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="hnav-mob-transition"
        unmountOnExit
        >
        {children(rest)}
      </CSSTransition>
    </div>
  )
});

export const HnavMob3Theme = HnavMob(({className, isOpen, children, ...rest}) => {

  return (
    <div className={classnames(className, 'hnav-mob_3')}>
      <div className="hnav__overlay"  onClick={rest.onClickClose} />

      <div className="open-btn" onClick={rest.onClickOpen}>
        <Icon iconName='hamburger' />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="hnav-mob-transition"
        unmountOnExit
        >
        {children(rest)}
      </CSSTransition>
    </div>
  )
});


export const HnavMobExt = HnavMob(({className, isOpen, list, sublist, ...rest} ) => {
  return (
    <div className={classnames(className, 'hnav-mob_ext')}>
      <div className="hnav__overlay"  onClick={rest.onClickClose} />
      <div className="open-btn" onClick={rest.onClickOpen}>
        <Icon iconName='hamburger' />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="hnav-mob-transition"
        unmountOnExit
      >
        <nav className='hnav__list'>
          <div className="hnav__sublist-main">
            {list.map((item, key) => {
              return(
                  <div className='hnav__item' onClick={rest.onClickClose} key={`hnavmob_${key}`}>
                    <NavLink className='hnav__link' to={item.link}>{item.name}</NavLink>
                  </div>
              )
            })}
          </div>
          <div className="hnav__sublist-secondary">
            {sublist.map((item, key) => {
              return(
                <div className='hnav__item' onClick={rest.onClickClose} key={`hnavsecmob_${key}`}>
                  <NavLink className='hnav__link' to={item.link}>{item.name}</NavLink>
                </div>
              )
            })}
          </div>
        </nav>
        </CSSTransition>
    </div>
  )
});
