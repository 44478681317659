export const HeaderNavListExt = [
  { name: 'TOP Nerds', link: '/top-nerds' },
  { name: 'For Businesses', link: '/businesses' },
  { name: 'For Students', link: '/students' },
  { name: 'For Academia', link: '/academia' },
  { name: 'About Us', link: '/about-us' },
];
export const HeaderNavSubList = [
  { name: 'Terms of Use', link: '/terms-and-conditions' },
  { name: 'Privacy Policy', link: '/privacy-policy' },
  { name: 'Cookie Policy', link: '/cookies' },
  { name: 'Refund Policy', link: '/refunds' },
  { name: 'Honor Code', link: '/honor-code' },
  { name: 'Report Abuse', link: '/report-abuse' },
];

export const HeaderNavListShort = [
  { name: 'TOP Nerds', link: '/top-nerds' },
  { name: 'About Us', link: '/about-us' },
];


