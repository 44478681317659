export default {
  email(value) {
    return /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(?:aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/i.test(value);
  },
  password(value) {
    return {
      isEmpty: !value.length > 0,
      isLatin: /[A-z]|[0-9]/.test(value),
    };
  },
  phone(value) {
    return /^\+?\d{4,14}$/.test(value);
  },
  amount(value) {
    return (/\d+/.test(value) && value > 0);
  },
};
