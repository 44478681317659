import React, { Component } from 'react';
import classnames from 'classnames';

import './index.css'


export class HiwList extends Component {
  render() {
    const { children } = this.props;

    return (
      <ul className='hiw__list'>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            index,
          })
        })}
      </ul>
    )
  }
}

export const HiwImg = (props) => {
  const { children, index } = props;

  return (
    <div className={`hiw__img hiw__img_${index + 1}`}>
      {children}
    </div>
  )
};

export const HiwText = (props) => {
  const { children } = props;

  return (
    <div className={`hiw__text-holder`}>
      {children}
    </div>
  )
};

export class HiwItem extends Component {
  render() {
    const { children, index } = this.props;

    return (
      <li className='hiw__item'>
        {React.Children.map(children, (child) => {
          switch(child.type.displayName) {
            case 'HiwImg':
              return React.cloneElement(child, {
                index
              });
            default:
              return React.cloneElement(child);
          }
        })}
      </li>
    )
  }
}

export default class Hiw extends Component {
  render () {
    const {className, children } = this.props;

    return (
      <div className={classnames('hiw', className)}>
        <div className='holder'>
          {children}
        </div>
      </div>
    )
  }
}
