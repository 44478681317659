import React from 'react';
import Section, { SectionItems } from '../../../../base_components/Section';
import Holder from './../../../../themes/nerdify/components/Holder';
import classnames from 'classnames';

import './index.css';

export const Section1 = (props) => {
  return <Section {...props} className={'section_1'} />
};

export const Section2 = ({ items, children }) => {
  return (
    <Section className={'section_2'}>
      <SectionItems items={items} />
      {children}
    </Section>
  )
};

export const Section3 = ({ items, children }) => {
  return (
    <Section className={'section_3'}>
      <SectionItems items={items} />
      {children}
    </Section>
  )
};

export const Section4 = ({ items, children, className }) => {
  return (
    <Section className={classnames('section_4', className)}>
      {children}
    </Section>
  )
};

export const Section5 = (props) => {
  return <Section {...props} className={'section_5'} />
};

export const Section6 = (props) => {
  return <Section {...props} className={classnames('section_6', props.indent)} />
};



export const Section7 = ({ children }) => {
  return (
    <Section className={'section_7'}>
      <Holder>
        {children}
      </Holder>
    </Section>
  )
};

export const Section8 = ({ children }) => {
  return (
    <Section className={'section_8'}>
      <Holder>
        {children}
      </Holder>
    </Section>
  )
};

export const Section9 = ({ children }) => {
  return (
    <div className={'section_9'}>
      <Holder>
        {children}
      </Holder>
    </div>
  )
};

export const Section10 = ({ children }) => {
  return (
    <div className={'section_10'}>
      <Holder className='holder_2'>
        {children}
      </Holder>
    </div>
  )
};

export const Section11 = ({ children, theme, indent }) => {
  return (
    <div className={classnames('section_11', {[`section_11_${theme}`]: theme}, {[indent] : indent})}>
      <Holder>
        {children}
      </Holder>
    </div>
  )
};

export const Section12 = ({ children }) => {
  return (
    <div className={classnames('section_12')}>
      {children}
    </div>
  )
};

export const SectionClp3 = ({ children }) => {
  return (
    <div className={classnames('section_clp3')}>
      <div className="holder">
        {children}
      </div>
    </div>
  )
};

export const SectionClp4 = ({ children }) => {
  return (
    <div className={classnames('section_clp4')}>
      <div className="holder">
        {children}
      </div>
    </div>
  )
};

export const Section14 = ({ children, theme }) => {
  return (
    <div className={classnames('section_14', {
      [`section_14_${theme}`]: theme
    })}>
      {children}
    </div>
  )
};

export const Section15 = ({ children, theme }) => {
  return (
    <div className={classnames('section_15', {
      [`section_15_${theme}`]: theme
    })}>
      {children}
    </div>
  )
};

export const SectionBlock = ({ children, theme, indent }) => {
  return (
    <div className={classnames('section',
      {[`theme_${theme}`]: theme},
      indent
    )}>
      {children}
    </div>
  )
};
