import React from 'react';
import classnames from 'classnames'

import './content.css';

const Content = ({ children, className, ...rest }) => {
  return (
    <div className={classnames('content', className)}>
      { children }
    </div>
  );
}

export default Content;