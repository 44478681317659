import React, {Component} from 'react'
import classnames from "classnames";
import {NavLink} from "react-router-dom";
import Icons from './../../../../base_components/Icons';
import investIcons from './img/icons.svg';
import './invest.css'

export const InvestIcon = (props) => {
  return <Icons icons={investIcons} className='svg-icon' iconName={props.iconName} height={props.height} width={props.width}/>
};

export const InvestList =  ({ items }) => {
  return (
    <div className="invest-list">
      {items.map((item, index) => {
        return (
          <div className="invest-item" key={`investItem${index}`}>
            <div className="invest-item-title">
              <InvestIcon iconName={item.icon.name} width={`${item.icon.width}px`} height={`${item.icon.height}px`}/>
              <div className="invest-item-title-text">{item.title}</div>
            </div>
            <ul className="invest-item-ul">
              {item.list.map((listItem, index) => {
                return (
                  <li className="invest-item-li" key={`investItem${index}-${listItem}`}>&bull;&nbsp;&nbsp;{listItem}</li>
                );
              })}
            </ul>
            <div className="link-wrapper">
              <NavLink className="link" to={item.linkUrl}>{item.linkMessage}</NavLink>
            </div>
          </div>
        )
      })}
    </div>
  )
};

export default class Invest extends Component {
  render () {
    const {className, indent, children} = this.props;
    return (
      <div className={classnames("invest", className, indent)}>
        {children}
      </div>
    )
  }
}


