import React, { Component } from 'react';
import classnames from 'classnames';

import './index.css';


export default class Info extends Component {
  render() {
    const { className, children } = this.props;

    return <div className={classnames('info', className)}>
      <div className="holder">
        {children}
      </div>
    </div>
  }
}
