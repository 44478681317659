import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';


import './index.css';
import imgType from './img/adding-text.gif';
import imgImages from './img/chatImages.jpg';
import imgNerd from './img/nerd.svg';
import imgReload from './img/reload-icon.svg';

const MessageUser = ({ children, animated }) => {
  const className = classnames('message', 'out', { animated });
  return (
    <div className={className}>
      <div className="messageInner">
        {children}
      </div>
    </div>
  );
};

const MessageUserImages = ({ animated, img }) => {
  const className = classnames('message', 'out', { animated });
  return (
    <div className={className}>
      <div className="messageInnerImages">
        <img src={img ? require(`./img/${img}`) : imgImages} alt="" />
      </div>
    </div>
  );
};

class MessageNerd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
    };
  }

  componentDidMount() {
    const { animated } = this.props;
    if (animated) {
      this.run();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.animated && this.props.animated) {
      this.run();
    }
    if (prevProps.animated && !this.props.animated) {
      clearTimeout(this.timeout);
      this.setState({ step: 0 });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  run() {
    clearTimeout(this.timeout);
    this.setState({ step: 1 });
    this.timeout = setTimeout(() => this.setState({ step: 2 }), 1500);
  }

  renderStep1() {
    const { step } = this.state;

    if (step !== 1) {
      return null;
    }

    return (
      <div className="imgTypeContainer">
        <img src={imgType} alt="" className="imgType" />
      </div >
    );
  }

  render() {
    const { children } = this.props;
    const { step } = this.state;

    const className = classnames('message', 'in', { animated: step === 2 });
    return (
      <div className="messageContainer">
        {this.renderStep1()}
        <div className={className}>
          <img src={imgNerd} className="senderIcon" alt="" />
          <div className="messageInner">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

class DemoChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      buttonVisible: false,
      hasRun: true,
    };

    this.run = this.run.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ hasRun: false }), 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    if (prevState.step !== step) {
      clearTimeout(this.timeout);
      if (step === 0) {
        this.timeout = setTimeout(() => this.setState({ step: 1 }), 100);
      }
      if (step === 1) {
        this.timeout = setTimeout(() => this.setState({ step: 2 }), 1000);
      }
      if (step === 2) {
        this.timeout = setTimeout(() => this.setState({ step: 3 }), 3000);
      }
      if (step === 3) {
        this.timeout = setTimeout(() => this.setState({ step: 4 }), 1000);
      }
      if (step === 4) {
        this.timeout = setTimeout(() => this.setState({ step: 5 }), 1000);
      }
      if (step === 5) {
        this.timeout = setTimeout(() => this.setState({ buttonVisible: true }), 2000);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  run() {
    clearTimeout(this.timeout);
    this.setState({ buttonVisible: false });
    this.setState((state) => ({ step: state.step === 0 ? 1 : 0 }));
  }

  onVisibilityChange(visible) {
    if (!visible) {
      return;
    }
    const { hasRun } = this.state;
    if (hasRun) {
      return;
    }
    this.setState({ hasRun: true });
    this.timeout = setTimeout(() => this.run(), 1000);
  }


  render() {
    const { step, buttonVisible, hasRun } = this.state;

    const {
      items = []
    } = this.props;

    const className = classnames('demoChat', { visible: step })
    const buttonClassName = classnames('chat-play-btn', { 'btn-visible': buttonVisible });
    return (
      <Fragment>
        <VisibilitySensor
          onChange={this.onVisibilityChange}
          partialVisibility="bottom"
          offset={{ bottom: -350 }}
          active={!hasRun}
        >
          <div className="demochat-wrapper">
            <div className={className}>
              {items.map((item, key) => {
                const {
                  type,
                  text,
                } = item;

                const index = key + 1;

                switch (type) {
                  case 'user':
                    return (
                      <MessageUser animated={step >= index} key={`message-user-${key}`}>
                        {text}
                      </MessageUser>
                    )
                  case 'nerd':
                    return (
                      <MessageNerd animated={step >= index} key={`message-nerd-${key}`}>
                        {text}
                      </MessageNerd>
                    )
                  case 'img':
                    return (
                      <MessageUserImages animated={step >= index} img={text} key={`message-img-${key}`} />
                    )
                }
              })}
            </div>
            <button className={buttonClassName} type="button" onClick={this.run}>
              <img className="reload-icon" src={imgReload} alt="" />
              <span>Play again</span>
            </button>
          </div>
        </VisibilitySensor>
      </Fragment>
    );
  }
}

export default DemoChat;
