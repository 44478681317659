import React, { Component } from 'react';
import classnames from 'classnames';

import './read-more-text.css'

class ReadMoreText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadMoreMode: false,
      setupComplete: false,
    };
  }

  componentDidMount() {
    const { onSetup } = this.props;
    const isReadMoreMode = this.textRef.offsetHeight > this.containerRef.offsetHeight;
    this.setState({ isReadMoreMode, setupComplete: true });
    onSetup(isReadMoreMode);
  }

  render() {
    const { height, containerClassName, textClassName, children, expanded } = this.props;
    const { setupComplete, isReadMoreMode } = this.state;

    const containerStyle = !expanded ? { maxHeight: height } : {};
    const textStyle = setupComplete ? {} : { visibility: 'hidden' };
    return (
      <div
        className={classnames('readMoreText', { collapsed: isReadMoreMode && !expanded }, { expanded: !isReadMoreMode && expanded }, containerClassName)}
        style={containerStyle}
        ref={(ref) => { this.containerRef = ref; }}
      >
        <p
          className={classnames('readMoreTextText', textClassName)}
          style={textStyle}
          ref={(ref) => { this.textRef = ref; }}
        >{children}</p>
      </div>
    );
  }
}

export default ReadMoreText;
