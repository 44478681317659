import React from 'react';
import classnames from "classnames";
import LazyLoad from 'react-lazyload';
import Holder from './../Holder';

import Nerds, {
  NerdsList,
  NerdsItem
} from '../../../../base_components/Nerds';

import './index.css';

const universityImg = [
  {
    img: 'mit.png',
    img2x: 'mit@2x.png',
    width: 115,
    height: 26
  },
  {
    img: 'stanford.png',
    img2x: 'stanford@2x.png',
    width: 55,
    height: 55
  },
  {
    img: 'harvard.png',
    img2x: 'harvard@2x.png',
    width: 105,
    height: 26
  },
  {
    img: 'campridge.png',
    img2x: 'campridge@2x.png',
    width: 118,
    height: 24
  },
  {
    img: 'oxford.png',
    img2x: 'oxford@2x.png',
    width: 162,
    height: 46
  },
];

export default (props) => {
  return <Nerds {...props} className={'nerds_1'} />
}

export const Nerds2 = ({children, indent}) => {
  return  <div className={classnames('nerds_2', indent)}>
    {children}
    <div className='nerds__list'>
      {universityImg.map((item, key) => {
        return (
          <LazyLoad key={`nerds__list-item-${key}`}>
            <img className="nerds__list-item"
              height={item.height} width="auto"
              src={require(`./img/${item.img}`)}
              srcSet={require(`./img/${item.img2x}`) + ' 2x'}
              alt=""/>
          </LazyLoad>
        )
      })}
      </div>
  </div>
};

export const NerdsClp3 = ({ className, children }) => {
  return  <div className={classnames("nerds_clp3",className)}>
    <Holder>
      {children}
    </Holder>
  </div>
};

export {
  NerdsList,
  NerdsItem
}
