import React from 'react';
import Slider from 'react-slick';
import Text from '../../../../base_components/Text'
import classnames from "classnames";

import './slickSlider1.css';

const responsive = [{
  breakpoint: 1020,
  settings: {
    slidesToShow: 1,
    dots: false,
    arrows: true
  },
}, {
  breakpoint: 1019,
  settings: {
    slidesToShow: 1,
    dots: false,
    arrows: false
  },
}];


export default ({ items, title, children, theme, className }) => (
  <div className={classnames("slick-slider1", {[`slick-slider1_${theme}`]: theme}, className)}>
    <div className="holder">
      <h3 className="h3 slick-title">{title || <Text>In last 24hrs our Nerds helped with 300+ requests</Text>}</h3>
      <div className="slick-wrapper">
        <Slider
          variableWidth
          useTransform={false}
          slidesToShow={1}
          infinite={true}
          responsive={responsive}>

          {items.map(item => (
            <div className="slick-item" key={`slick-item-${item}`}>
              <div className="item-title">{item.title}</div>
              <div className="item-msg">"{item.msg}"</div>
            </div>
          ))}
        </Slider>
        {children}
      </div>
    </div>
  </div>
);


const responsiveClp3 = [{
  breakpoint: 1020,
  settings: {
    slidesToShow: 1,
    dots: false,
    arrows: true
  },
}, {
  breakpoint: 1019,
  settings: {
    slidesToShow: 1,
    dots: false,
    arrows: true
  },
}];

export const SlickSlider1Clp3 = ({ items, title, children, className }) => (
  <div className={classnames("slick-slider1",className)}>
    <div className="holder">
      <h3 className="h3 slick-title">{title || <Text>In last 24hrs our Nerds helped with 300+ requests</Text>}</h3>
      <div className="slick-wrapper">
        <Slider
          variableWidth
          useTransform={false}
          slidesToShow={1}
          infinite={true}
          responsive={responsiveClp3}>

          {items.map(item => (
            <div className="slick-item" key={`slick-item-${item}`}>
              <div className="item-title">{item.title}</div>
              <div className="item-msg">"{item.msg}"</div>
            </div>
          ))}
        </Slider>
        {children}
      </div>
    </div>
  </div>
);
