import React from 'react';
import pressIcons from './img/icons.svg';
import Icons from './../../../../base_components/Icons';
import Press, {
  PressList,
  PressItem
} from  './../../../../base_components/Press';

import './press.css';
//import classnames from "classnames";

export default (props) => {
  return <Press {...props} />
}

export const PressIcon = (props) => {
  return <Icons icons={pressIcons} className='svg-icon' iconName={props.iconName} />
};

export {
  PressItem,
  PressList
};


