import React, { Component } from 'react';
import classnames from 'classnames';
import Swipe from 'react-easy-swipe';


export const HnavMob = (WrappedComponent) => {
  class HnavMob extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false
      };

      this.onClickOpen = this.onClickOpen.bind(this);
      this.onClickClose = this.onClickClose.bind(this);
      this.onSwipeMove = this.onSwipeMove.bind(this);
    }

    onClickOpen() {
      this.setState({
        isOpen: true,
      });

      //document.querySelector('body').classList.add('fixed');
      document.querySelector('html').classList.add('fixed');
    }

    onClickClose() {
      this.setState({
        isOpen: false,
      });

      //document.querySelector('body').classList.remove('fixed');
      document.querySelector('html').classList.remove('fixed');
    }

    onSwipeMove(position) {
      if (position.x <= -50) {
        this.onClickClose();
      }
    }

    render() {
      const { children } = this.props;
      const { isOpen } = this.state;

      return (
        <Swipe
        onSwipeMove={this.onSwipeMove}
        >
          <WrappedComponent
            className={classnames('hnav-mob', { 'hnav-mob_open': isOpen })}
            onClickClose={this.onClickClose}
            onClickOpen={this.onClickOpen}
            isOpen={isOpen}
            list={this.props?.list}
            sublist={this.props?.sublist}
          >
            {children}
          </WrappedComponent>
        </Swipe>
      )
    }
  }

  HnavMob.displayName = `HnavMob(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return HnavMob;
};
