import React from 'react';
//import Icon from '../Icons';
import HeroBuilder from '../../../../base_components/HeroBuilder'

import './index.css'

const Hero = ({
  onRef,
  handleOnClickScrollTo,
  children
}) => (
    <div className="hero hero--9" ref={el => onRef(el)}>
      <div className="holder holder_1">
        {children}
      </div>
    </div>
  )

export default HeroBuilder(Hero)
