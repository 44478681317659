import React from 'react';
import DemoCards from '../../../../themes/nerdify/components/DemoCards';

export default (props) => {
  return (
    <DemoCards
      records={{
        "facebook": {
          "01": {
            "id": "01",
            "date": "Apr 10, 2020",
            "name": "Veronica D.",
            "photo": "Veronica D.jpg",
            "photo2x": "Veronica D@2x.jpg",
            "recommends": true,
            "review": "In fact, am really amazed by how Nerdify helps you get through some tough topics. I was afraid since it was my first time using the service and l didn't know whether to believe or trust something online. But after helping me l think you guys are the best! Wonderful, affordable, prompt service. I will use it again definitely!"
          },
          "02": {
            "id": "02",
            "date": "Apr 2, 2020",
            "name": "Sunny R.",
            "photo": "Sunny R.jpg",
            "photo2x": "Sunny R@2x.jpg",
            "recommends": true,
            "review": "I love it. They help me a lot with my online chores. I choose Nerdify and trust them! They always do whatever it takes to make sure I am satisfied with the service. My favorite Nerd is Mark - he is a true star! I have never ever had any trouble with him and his professional and empathetic approach is awesome. Kudos to Mark!"
          },
          "03": {
            "id": "03",
            "date": "Mar 30, 2020",
            "name": "Autumn S.",
            "photo": "Autumn S.jpg",
            "photo2x": "Autumn S@2x.jpg",
            "review": "They helped me proofread my work a few times and it was ABSOLUTELY PERFECT. They are courteous, on time and very consistent with communication. They always pay attention to details on whatever I need. The prices are also very reasonable and depend on urgency. I would recommend Nerdify to anyone whose life gets too busy sometimes to keep up with a pace. Thank you for everything!",
            "recommends": true,
            "response": "Thank you so much, Autumn! Can't wait to help you further :)"
          },
          "04": {
            "id": "04",
            "date": "Mar 20, 2020",
            "name": "Alejandra P.",
            "photo": "Alejandra P.jpg",
            "photo2x": "Alejandra P@2x.jpg",
            "review": "Honestly, I’m so overjoyed and happy! My whole experience it was simply amazing in terms of quality, honesty and time. Plus the price was so GOOD! So yes, I officially love Nerdify and I recommend it 100%! Thank you Nerdify for being my savior at a time when I was most stressed and afraid I wouldn’t find the suitable help. I’ll admit, it was sort of a gamble for me personally when I first requested their help because I had never tried them or a similar service before in my life. So it was basically a 50/50 that it would work. But I chose to follow my gut and trust them, and believe me I have never made a better choice. Also in terms of their response when you need to ask a question, it says that Nerdify typically replies in minutes, and they do! So it’s not a fraud, it’s the complete opposite. I’m just so happy, and I’m definitely asking Nerdify for more help in the future.",
            "recommends": true,
          },
          "05": {
            "id": "05",
            "date": "Mar 27, 2020",
            "name": "Catherine K.",
            "photo": "Catherine K.jpg",
            "photo2x": "Catherine K@2x.jpg",
            "recommends": true,
            "review": "The Nerds really help me out. They have Nerds in pretty much any subject you might need help with. If you need a little tutoring help they’re here to save the day! This is great."
          },
          "06": {
            "id": "06",
            "date": "Mar 27, 2020",
            "name": "Kasper G.",
            "photo": "Kasper G.jpg",
            "photo2x": "Kasper G@2x.jpg",
            "recommends": true,
            "review": "They’re amazing! The Nerds are always 100% helpful. I’ve never ever trusted these kinds of platforms. But when I was running out of time I started looking for a solution and that is how I came across Nerdify. They reached out to me via text messages in a professional way that made me trust their words easily and I don’t regret it at all."
          },
          "07": {
            "id": "07",
            "date": "Mar 15, 2020",
            "name": "Tara C.",
            "photo": "Tara C.jpg",
            "photo2x": "Tara C@2x.jpg",
            "recommends": true,
            "review": "I wanted to share my first experience using Nerdify - it was amazing. One of my friends gave me a number to text, and as soon as I texted it I got a response. The person on the other side was very helpful and careful. She helped me a lot answering all my questions immediately and updating me on my task processing. I will definitely use Nerdify again. These guys are quick, smart, and extremely attentive. If you use their service, YOU WILL NOT BE DISAPPOINTED!",
            "response": "Wow, thanks for your feedback. <3"
          },
          "08": {
            "id": "08",
            "date": "Mar 14, 2020",
            "name": "Jermain C.",
            "photo": "Jermain C.jpg",
            "photo2x": "Jermain C@2x.jpg",
            "recommends": true,
            "review": "This semester, I can honestly say it was pretty hard. With Nerdify's assistance, I was able to study and put forth better efforts towards passing my classes. Thank you guys for all the help!"
          },
          "09": {
            "id": "09",
            "date": "Mar 13, 2020",
            "name": "Trey R.",
            "photo": "Trey R.jpg",
            "photo2x": "Trey R@2x.jpg",
            "recommends": false,
            "review": "Requested help but they notified in 30 min saying the Nerd was not available :(",
            "response": "Dear Trey, please accept our sincere apologies for this situation. Unfortunately, sometimes due to various reasons we are unable to provide assistance. This can happen due to a heavy workload of your Nerd or some family issues. In this particular situation your Nerd experienced tech difficulties and was unable to assist you on time. We will gladly add a bonius to your account as an apology and hope you give us a second chance!"
          },
          "10": {
            "id": "10",
            "date": "Mar 12, 2020",
            "name": "Karina L.",
            "photo": "Karina L.jpg",
            "photo2x": "Karina L@2x.jpg",
            "recommends": true,
            "review": "I’ve used Nerdify for the stuff I had trouble with. They are great at doing their job and providing help any time I need it! 😊"
          },
          "11": {
            "id": "11",
            "date": "Mar 12, 2020",
            "name": "Jocabed S.",
            "photo": "Jocabed S.jpg",
            "photo2x": "Jocabed S@2x.jpg",
            "recommends": true,
            "review": "This is the most convenient way to hire a person who can explain to you everything you need in the specific subject. Thanks to Nerdify I managed to get through my Biology and History courses. I never considered other companies after I found Nerdify."
          },
          "12": {
            "id": "12",
            "date": "Mar 5, 2020",
            "name": "Curtis R.",
            "photo": "Curtis R.jpg",
            "photo2x": "Curtis R@2x.jpg",
            "recommends": true,
            "review": "Was a last-minute lifesaver... I needed someone to help me out with a presentation and I contacted Nerdify at 6 am. They assigned me a Nerd right away and I managed to make it to the deadline. Satisfied with their work but wish the price was cheaper."
          },
          "13": {
            "id": "13",
            "date": "Feb 27, 2020",
            "name": "Talita F.",
            "photo": "Talita F.jpg",
            "photo2x": "Talita F@2x.jpg",
            "recommends": true,
            "review": "They are great, attentive and trustworthy!"
          },
          "14": {
            "id": "14",
            "date": "Feb 27, 2020",
            "name": "Mykala F.",
            "photo": "Mykala F.jpg",
            "photo2x": "Mykala F@2x.jpg",
            "recommends": true,
            "review": "This company is absolutely 100% reliable and honest. They will text you within seconds and they make sure your emergency isn’t an emergency anymore. I will definitely be using and recommending it! Thanks again!"
          },
        },

        "trustpilot": {
          "01": {
            "date": "Apr 1, 2020",
            "name": "Jennifer A.",
            "mark": "5",
            "title": "My honest review",
            "review": "I really like this service. With my job basically taking over my life now: working from home + keeping up with online classes became really difficult. But with Nerdify, I am confident I have a plan B once I need someone to help me out."
          },
          "02": {
            "date": "Mar 31, 2020",
            "name": "BK",
            "mark": "5",
            "title": "Very happy with their work",
            "review": "I needed them for my project and they did an amazing job answering all my questions. I will recommend Nerdify to everyone and I’ll work with Nerds again for sure. Thank you for your help!",
          },
          "03": {
            "date": "Mar 30, 2020",
            "name": "Lanasha D.",
            "mark": "5",
            "title": "I’ve been using it for a while",
            "review": "I am always impressed with their work and help! I always make sure to be very specific and thus never had any problems with my Personal Nerd. In fact, this is one of the best websites I have ever used! Very professional, helpful, fast and accurate! You won’t be disappointed 😉"
          },
          "04": {
            "date": "Mar 20, 2020",
            "name": "Brandon W.",
            "mark": "4",
            "title": "Amazing Work!",
            "review": "Nothing in life is perfect and neither are they - but they are damn close to perfection. I have had over a dozen requests and I had trouble with just one, but it was refunded right away. They have excellent response time and amazing customer service. I would definitely recommend this company: they are legit and the real deal."
          },
          "05": {
            "date": "Mar 27, 2020",
            "name": "Adreinn T.",
            "mark": "3",
            "title": "Could be awesome, but not:(",
            "review": "I have used them many times and they were always the nicest, most professional and helpful people you will deal with. My help requests are always completed on time except for the last two times. This could be an awesome experience, but now I don't know whether to trust Nerdify with my future tasks.",
            "response": "Dear Adreinn, we are extremely sorry to hear that you've had such a frustrating experience! From what I see in our client management system, your Nerd had some tech issues and could not complete your request on time. We have decided to initiate a full refund and promise to take all measures to prevent such issues from happening in future. We really hope to see you back!"
          },
          "06": {
            "date": "Mar 27, 2020",
            "name": "Diana B.",
            "mark": "5",
            "title": "They helped me a lot!",
            "review": "I had a business project that I was overwhelmed with finishing up and it wasn't an easy task. But the Nerd really did a good job of putting it together and organizing it to make sense and sound more professional. I really appreciate that I got updates via SMS. When I had any questions someone quickly answered regardless of the time of the day. I will definitely use this service again!",
          },
          "07": {
            "date": "Mar 21, 2020",
            "name": "Tiffany",
            "mark": "4",
            "title": "Not ideal, but nice",
            "review": "Life gets extremely busy and having help is greatly appreciated. Knowing that I have Nerds covering my back brings me peace, however they failed to find a Nerd for my latest task. My point: try Nerdify, but don't put all eggs in one basket.",
            "response": "Hey there, Tifanny! So sorry we could not help you this time. The Nerds specializing in your particular subject are in high demand. Due to the recent heavy workload the one assigned to you contacted us saying she could not make it on time, that's why we failed to provide you necessary help. We added a $10 bonus to your account as an excuse for the situation and hope you give us a second chance!"
          },
          "08": {
            "date": "Mar 14, 2020",
            "name": "Tom K.",
            "mark": "5",
            "title": "Loving it",
            "review": "With my busy schedule being a full-time worker and full-time student I don't have a lot of time for my courses. With the help of this company, I keep my studies in order. Very professional and easy to reach. I am a returning customer. Surely I will use them again in the future."
          },
          "09": {
            "date": "Mar 15, 2020",
            "name": "Felicia M.",
            "mark": "5",
            "title": "Outstanding company",
            "review": "I was introduced to Nerdify about a year ago by a good friend of mine. After doing my research about them, I began using Nerdify and they are the greatest. I love the fact that they have experts in any area that you may need them for. The level of communication with them is astonishing. They communicate with you throughout the entire process. They thoroughly take the time out to make sure your needs are met. All of my needs were met by Nerdify. The very most important attribute Nerdify displayed to me is professionalism, which is very exquisite. I highly recommend Nerdify."
          },
          "10": {
            "date": "Mar 11, 2020",
            "name": "Latoya B.",
            "mark": "5",
            "title": "Thanks a lot!",
            "review": "I found these guys by googling. Yes, at first I was very skeptical about the entire situation, I didn't really think.it was legit... But I was desperate and really need help with my course so I decided to give it a try. Boy, I am glad I did. These people are.very friendly and pleasant and are willing to help. I will definitely work with them again.Thank you Nerdify!!!"
          },
          "11": {
            "date": "Mar 3, 2020",
            "name": "Mikaela F.",
            "mark": "5",
            "title": "Excellent Source for Help",
            "review": "I have used Nerdify a few times now and it has been nothing but amazing. Super helpful and reliable. I love that you guys are right there when I need it. And I would recommend others to use Nerdify as well. I have referred a couple of people to you and will continue to."
          },
          "12": {
            "date": "Feb 27, 2020",
            "name": "D B",
            "mark": "5",
            "title": "Nerdify Honest Overview",
            "review": "I’ve searched forever for a team of smart and trustworthy individuals to help me get through a few courses for school, which I’ve now found with Nerdify. I trust this team 100% no doubt. The quotes are, in my opinion, perfect. Not overpriced at all which is greatly appreciated. This team is patient and will answer any and all of your questions. Nerdify is persistent and dedicated to helping you the best they can."
          },
          "13": {
            "date": "Feb 25, 2020",
            "name": "Marissa B.",
            "mark": "5",
            "title": "Nerdify is a lifesaver",
            "review": "Nerdify has literally saved my life when it comes to school. They contact you back within seconds and always do a great job. I only had a problem once, which turned out to be just a misunderstanding. They are convenient, accurate and reliable. I highly recommend them to anyone."
          },
          "14": {
            "date": "Feb 17, 2020",
            "name": "Helio Q.",
            "mark": "5",
            "title": "Nerds are the best!",
            "review": "I have passed some of my most difficult classes thanks to the great freelancers on this platform. They are always willing to help others any day and at any time.  I truly appreciate your help."
          }
        }
      }}
    />
  )
};
