import React, { Component, Fragment, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

import Item from './Item';

class Cards extends Component {
  constructor(props) {
    super(props);

    const { source, records } = this.props;
    const items = Object.keys(records[source]).sort();

    this.state = {
      active: 0,
      direction: '',
      source,
      records,
      items,
    };

    this.moveLeft = this.moveLeft.bind(this);
    this.moveRight = this.moveRight.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (this.props.source !== prevProps.source) {
      const { source, records } = this.props;
      const items = Object.keys(records[source]).sort();

      this.setState({
        source,
        items,
      });
    }
  }

  generateItems() {
    const { active, items, source } = this.state;
    const { records } = this.props;

    const result = [];
    for (let i = active - 1; i < active + 2; i++) {
      let index = i;
      if (i < 0) {
        index = items.length + i;
      } else if (i >= items.length) {
        index = i % items.length;
      }
      const level = active - i;

      let onClick = () => {};
      if (level < 0) {
        onClick = this.moveRight;
      } else if (level > 0) {
        onClick = this.moveLeft;
      }
      const value = records[source][items[index]];

      result.push(
        <CSSTransition key={`item-${source}-${index}`} timeout={1000}>
          <Item
            value={value}
            level={level}
            onClick={onClick}
            source={source}
            ref={this.getRef}
          />
        </CSSTransition>
      );
    }

    return result;
  }

  moveLeft() {
    this.setState((state) => {
      const newActive = state.active - 1;
      return {
        active: newActive < 0 ? state.items.length - 1 : newActive,
        direction: 'left',
      };
    });
  }

  moveRight() {
    this.setState((state) => {
      const newActive = state.active + 1;
      return {
        active: newActive % state.items.length,
        direction: 'right',
      };
    });
  }

  render() {
    const { direction, active } = this.state;
    const { children, isMobile } = this.props;

    return (
      <div className={classnames('cards cards_0')}>
        <TransitionGroup
          className="activeContainer"

          childFactory={(child) =>
            React.cloneElement(child, { classNames: direction })
          }
        >
          {this.generateItems()}
        </TransitionGroup>
        {!isMobile && (
          <Fragment>
            <div className="cardsArrow" onClick={this.moveRight} />
            <div
              className="cardsArrow cardsArrowPrev"
              onClick={this.moveLeft}
            />
          </Fragment>
        )}
        {children && children({ active })}
      </div>
    );
  }
}

export default Cards;
