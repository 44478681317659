import React, { Component } from 'react';
import Uploader from './../../../../utils/uploader';

import Icon from './../Icons';

import './attach.css';

const AttachRemove = ({ onClick, ...rest }) => {
  return (
    <div className="attachBlock-progress-remove" onClick={onClick} title="Remove file" />
  )
}

class Attach extends Component {
  constructor(props) {
    super(props);

    this.uploader = new Uploader({
      onAddedFiles: (files) => {
        this.setState({
          files: files,
        });
      },
      onProgressFiles: (files) => {
        this.setState({
          files: files,
        });
      },
      onLoadedFile: (file) => {
        this.setState({
          files: file.filter(item => item.status === 'loaded')
        });
      },
      onRemovedFile: (file) => {
        this.setState({ files: file });
      },
    });

    this.onChange = this.onChange.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onClear = this.onClear.bind(this);

    this.initialState = {
      files: [],
    }

    this.state = this.initialState;
  }

  componentDidUpdate(prevProps) {
    const { clear } = this.props;

    if (clear && clear !== prevProps.clear) {
      this.onClear();
    }
  }

  onClear() {
    this.setState({ ...this.initialState });
  }

  onChange(e) {
    this.uploader.addFiles([e.target.files[0]])
  }

  onClickRemove(e, id) {
    e.preventDefault();
    const { files } = this.state;

    if (files.length > 0) {
      this.uploader.removeFile(id);
    }
  }

  render() {
    const { id } = this.props;
    const { files } = this.state;
    const file = files[0];
    const loading = file && file.status !== 'loaded';

    return (
      <div className="attachBlock">
        {loading ?
          <div className="attachBlock-progress">
            <div className="attachBlock-progress-bar" style={{width: `${file.progress}%` }} />
            <AttachRemove onClick={(e) => {this.onClickRemove(e, file.id)}} />
          </div>
        :
          <label htmlFor={id}>
            <input type="file" id={id} onChange={this.onChange} />
            <Icon className="icon-attach" iconName="attach" />

            {file ?
              <div className="attachBlock-file">
                <span className="p">{file.file}</span>
                <AttachRemove onClick={(e) => {this.onClickRemove(e, file.id)}} />
              </div>
            :
              <span>{ this.props.children }</span>
            }

          </label>
        }
      </div>
    );
  }
}

export default Attach;
