import React, { Component } from 'react'
import Holder from './../../../../themes/nerdify/components/Holder';
import Icon from './../../../../themes/nerdify/components/Icons';
import { Link } from 'react-router-dom';
import Scroll from 'react-scroll';

import TextPage from './../TextPage';

import { Section4 } from '../../../../themes/nerdify/components/Section';
import { TextBack2 } from '../../blocks/TextBack'

import { Intro1, Intro4 } from '../../../../themes/nerdify/components/Intro';
import { NerdImg } from '../../../../themes/nerdify/components/TopNerds';

import '../../../../themes/nerdify/components/TopNerds/index.css';

import LocalesPage from './../LocalesPage';

const PAGE_SIZE = 10;

const nerdsList = [
  {
    name: 'David N.',
    rating: 4.5,
    requests: 554,
    degree: 'Bachelor\'s',
    major: 'Economics and Management of Enterprise',
    competences: 'Economics, Finance, Accounting and Banking, Math',
    img: 'David_N'
  },
  {
    name: 'Sam A.',
    rating: 5,
    requests: 115,
    degree: 'Bachelor\'s',
    major: 'Electrical Engineering',
    competences: 'Engineering, Math, Physics, Analytics, Statistics',
    img: 'Sam_A'
  },
  {
    name: 'Victoria H.',
    rating: 4.5,
    requests: 381,
    degree: 'Bachelor\'s',
    major: 'Education',
    competences: 'Culture, Education, English, Literature',
    img: 'Victoria_H'
  },
  {
    name: 'Isabella L.',
    rating: 5,
    requests: 707,
    degree: 'Bachelor\'s',
    major: 'History and Politics',
    competences: 'History and Anthropology, International Relations, Political Science, Religion, Sociology',
    img: 'Isabella_L'
  },
  {
    name: 'Sabrina A.',
    rating: 4.5,
    requests: 1080,
    degree: 'Master’s',
    major: 'English Studies',
    competences: 'Creative Writing, English, Content Writing',
    img: 'Sabrina_A'
  },
  {
    name: 'Jeremy J.',
    rating: 4.5,
    requests: 124,
    degree: 'Bachelor\'s',
    major: 'Legal Management',
    competences: 'Law and International Law',
    img: 'Jeremy_J'
  },
  {
    name: 'David R.',
    rating: 5,
    requests: 548,
    degree: 'Master’s',
    major: 'Management',
    competences: 'Business and Management, Economics, Finance, Accounting and Banking, Human Resource Management, Marketing and PR',
    img: 'David_R'
  },
  {
    name: 'Julia P.',
    rating: 4.5,
    requests: 3508,
    degree: 'Master’s',
    major: 'English Language',
    competences: 'Copywriting, Italian, Spanish, English, Translation, Content Management',
    img: 'Julia_P',
    horisontalPhoto: true,
  },
  {
    name: 'Dylan S.',
    rating: 5,
    requests: 292,
    degree: 'Bachelor\'s',
    major: 'Linguistics',
    competences: 'Education, English, History and Anthropology, Linguistics, Literature',
    img: 'Dylan_S'
  },
  {
    name: 'John K.',
    rating: 4.5,
    requests: 778,
    degree: 'Master\'s',
    major: 'Organization Management',
    competences: 'Business and Management, Economics, English, History and Anthropology, IT, International Relations, Marketing and PR, Political Science, Psychology, Sociology',
    img: 'John_K'
  },
  {
    name: 'Mario O.',
    rating: 5,
    requests: 243,
    degree: 'Bachelor\'s',
    major: 'Economics',
    competences: 'Economics, Investment Research, Financial Analysis',
    img: 'Mario_O'
  },
  {
    name: 'Clark J.',
    rating: 4.5,
    requests: 148,
    degree: 'Bachelor\'s',
    major: 'Commerce',
    competences: 'Business and Management, Economics, English, Finance, Accounting and Banking, Healthcare and Nursing, Human Resource Management, Law and International Law, Literature, Psychology, Sociology',
    img: 'Clark_J'
  },
  {
    name: 'Mary R.',
    rating: 5,
    requests: 386,
    degree: 'Master\'s',
    major: 'MBA',
    competences: 'Market Research, Lead Generation, Business and Management',
    img: 'Mary_R',
    horisontalPhoto: true,
  },
  {
    name: 'Sophia S.',
    rating: 4.5,
    requests: 268,
    degree: 'Master\'s',
    major: 'Economics',
    competences: 'Tutoring, Investment Research, Financial Analysis',
    img: 'Sophia_S',
    horisontalPhoto: true,
  },
  {
    name: 'Jon P.',
    rating: 5,
    requests: 432,
    degree: 'Master\'s',
    major: 'Business Administration',
    competences: 'Human Resources, Labor Code, Legal Writing',
    img: 'Jon_P'
  },
  {
    name: 'Fabian D.',
    rating: 4.5,
    requests: 163,
    degree: 'Bachelor\'s',
    major: 'Philosophy',
    competences: 'Art, Culture, Education, English, History and Anthropology, International Relations, Law and International Law, Philosophy, Psychology, Sociology',
    img: 'Fabian_D'
  },
  {
    name: 'Bryan B.',
    rating: 5,
    requests: 108,
    degree: 'Bachelor\'s',
    major: 'Political Science',
    competences: 'Culture, English, Healthcare and Nursing, History and Anthropology, International Relations, Literature, Philosophy, Political Science, Religion, Sociology',
    img: 'Bryan_B'
  },
  {
    name: 'Ella C.',
    rating: 4.5,
    requests: 82,
    degree: 'Bachelor\'s',
    major: 'Pharmacy',
    competences: 'Chemistry, Culture, English, Healthcare and Nursing, Psychology, Sociology',
    img: 'Ella_C'
  },
  {
    name: 'Pedro G.',
    rating: 4.5,
    requests: 176,
    degree: 'Associate',
    major: 'Lingustics',
    competences: 'Press Releases, English, Linguistics, SMM, Creative Writing',
    img: 'Pedro_G'
  },
  {
    name: 'Anthony S.',
    rating: 5,
    requests: 97,
    degree: 'Bachelor\'s',
    major: 'Computer Science',
    competences: 'Landing Pages, HTML, Laravel Framework',
    img: 'Anthony_S'
  },
];

const Nerd = ({ item }) => (
  <div className="nerds-item nerds-item__show">
    <div className="nerds-item__photo">
      <NerdImg item={item} />
    </div>

    <div className="nerds-item__info">
      <h3 className="p nerds-item__info__name">{item.name}</h3>
      <div className="nerds-item__rating">
        <Icon iconName="star-orange" className="icon-star-20" />
        <Icon iconName="star-orange" className="icon-star-20" />
        <Icon iconName="star-orange" className="icon-star-20" />
        <Icon iconName="star-orange" className="icon-star-20" />
        {item.rating === 5 &&
          <Icon iconName="star-orange" className="icon-star-20" />
        }
        {item.rating !== 5 &&
          <Icon iconName="star-orange-half" className="icon-star-20" />
        }
      </div>

      <div className="nerds-item__info__top">
        <div className="nerds-item__info__desc-wrap">
          <h4 className="p p__small p__darker nerds-item__info__desc">
            <Icon iconName="papers" className="icon-papers nerds-item__icon" />
            Completed requests: <strong>{item.requests}</strong>
          </h4>
          <h4 className="p p__small p__darker nerds-item__info__desc">
            <Icon iconName="hat" className="icon-hat nerds-item__icon" />
            Degree: <strong>{item.degree}</strong>
          </h4>
          <h4 className="p p__small p__darker nerds-item__info__desc">
            <Icon iconName="check-round" className="icon-check nerds-item__icon" />
            Major: <strong>{item.major}</strong>
          </h4>
        </div>
      </div>

      <h5 className="p p__title nerds-item__title">Competences:</h5>
      <p className="p p__small p__darker nerds-item__competences">{item.competences}</p>
      <Scroll.Link to="form" className="button button__type10 nerds-item__btn" smooth offset={-120}>Hire a Nerd</Scroll.Link>
    </div>
  </div>
)

class TopNerds extends Component {

  state = {
    page: 1
  };

  onNextPage = () => {
    this.setState({ page: this.state.page + 1 })
  };

  render() {
    const nerds = nerdsList.slice(0, this.state.page * PAGE_SIZE)
    const isShowNextPage = nerdsList.length > nerds.length

    return (
      <LocalesPage {...this.props}>
      <TextPage className="topNerds" noWrap au>
        <Intro1>
          <Holder className="holder_1">
            <h1 className="h1">Meet TOP Nerds</h1>
            <h6 className="h6">Looking for an expert to help you with another endless task list? Trust the algorithm to find you the best-fit freelance Nerd on our platform!</h6>
          </Holder>
        </Intro1>

        <Holder className="holder_1">


          <div className="nerds-list">
            {nerds.map(nerd => <Nerd item={nerd} key={nerd.name} />)}

            {isShowNextPage &&
              <button
                className="button button__type9 top-nerds__show-more"
                onClick={this.onNextPage}
              >
                Show more Nerds
              </button>
            }
          </div>
        </Holder>

        <Intro4 indent={"pt60d pb60d pt20m pb40m"}>
          <Holder className={"holder_3"}>
            <div className="intro__title">Disclaimer</div>
            <div className="intro__msg">Please be aware that freelance Nerds agreed to follow our Honor Code that does not allow providing any type of service that violates academic, corporate or any other policies. Please read our <Link className="a__underline" to="/honor-code">Honor Code</Link> and <Link className="a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</div>
          </Holder>
        </Intro4>

        <Section4>
          <Holder>
            <div className='h1' id={'form'}>Still doubting whether you need a Nerd?</div>
            <h5 className="h5">Message us to learn more about what Nerds can help with!</h5>
            <TextBack2 />
          </Holder>
        </Section4>

        <Holder className="holder_1" indent={'pt40m pt60d'}>
          <div className="top-nerds__banner">
            <div className="top-nerds__banner__pic" />
            <div className="top-nerds__banner__title-group">
              <h2 className="h4 h4__custom top-nerds__banner__title">Wanna become a member of the Nerdy network?</h2>
              <h3 className="p p__custom top-nerds__banner__subtitle">Apply to become a Nerd in a few easy steps.</h3>
            </div>
            <Link className="button button__type6 top-nerds__banner__lnk" to="/become">Become a Nerd</Link>
          </div>
        </Holder>

      </TextPage>
      </LocalesPage>
    )
  }
}

export default TopNerds

