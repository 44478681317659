import React, {
  Fragment
} from 'react';
import { NavLink } from 'react-router-dom';
import Icon from './../Icons';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';

import './index.css';

const TopNerdsInfo = ({ content, theme }) => {
  return (
    <div className={classnames('top-nerds', {
      [`top-nerds_${theme}`]: theme
    })}>
      <div className="top-nerds-block">
        <div className="top-nerds__title">We select the top 2%<br className="mobile-only" /> of Nerds<br className="desktop-only" /> in each field</div>
        <div className="top-nerds-subtitle">Nerds are freelancers who passed a rigid examination and agreed with our {' '}
        {content === 1
          ? (<Fragment>Honor Code</Fragment>)
          : (<NavLink className="link" to="/honor-code">Honor Code</NavLink>)}
         {' '} to join Nerdify platform.
        </div>
        {content !== 1 && content !== 2 && (<div className="top-nerds-btn-block">
          <NavLink className="button__type14" to='/top-nerds'>
            <Icon className={`svg-icon`} iconName="small-arrow" />
            <div>Meet the Nerds</div>
          </NavLink>
          <NavLink className="a a__type1" to="/become">Apply to be a Nerd</NavLink>
        </div>)}
      </div>
      <LazyLoad height={282}>
        <img className="top-nerds-img" src={require(`./img/top-nerds.svg`)} alt={''} />
      </LazyLoad>
    </div>
  )
};

const TopNerdsInfo2 = ({ content, theme }) => {
  return (
    <div className={classnames('top-nerds', {
      [`top-nerds_${theme}`]: theme
    })}>
      <div className="top-nerds-block">
        <div className="top-nerds__title">We only select the TOP 2% of freelancers in each field</div>
        <div className="top-nerds-subtitle">Nerds are qualified freelancers in their area, who have proven their expertise in the uncompromising examination process. They all also agreed to follow our {' '}
        {content === 1
          ? (<Fragment>Honor Code</Fragment>)
          : (<NavLink className="link" to="/honor-code">Honor Code</NavLink>)}
         {' '} prior to joining Nerdify platform.
        </div>
        {content !== 1 && (<div className="top-nerds-btn-block">
          <NavLink className="button__type14" to='/top-nerds'>
            <Icon className={`svg-icon`} iconName="small-arrow" />
            <div>Check the Nerds</div>
          </NavLink>
          <NavLink className="a a__type1" to="/become">Apply to be a Nerd</NavLink>
        </div>)}
      </div>
      <LazyLoad height={282}>
        <img className="top-nerds-img" src={require(`./img/top-nerds.svg`)} alt={''} />
      </LazyLoad>
    </div>
  )
};

const TopNerdsInfo3 = ({ theme }) => {
  return (
    <div className={classnames('top-nerds', {
      [`top-nerds_${theme}`]: theme
    })}>
      <div className="top-nerds-block">
        <div className="top-nerds__title">Our Nerds are among the TOP 2% freelancers in their field</div>
        <div className="top-nerds-subtitle ">They all are experts with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</div>
        <div className="top-nerds-btn-block">
          <NavLink className="button__type14" to='/top-nerds'>
            <Icon className={`svg-icon`} iconName="small-arrow" />
            <div>Meet the Nerds</div>
          </NavLink>
          <NavLink className="a a__type1" to="/become">Apply to be a Nerd</NavLink>
        </div>
      </div>
      <LazyLoad height={282}>
        <img className="top-nerds-img" src={require(`./img/top-nerds.svg`)} alt={''} />
      </LazyLoad>
    </div>
  )
};

const TopNerdsInfoImg = ({img, img2x, className, ...rest}) => <img className={className} src={require(`./img/${img}`)} srcSet={require(`./img/${img2x}`)+` 2x`} alt={''}/>;

const TopNerdsInfoClp3 = ({ className, children }) => {
  return <div className={classnames('top-nerds_clp3', className)}>
    {children}
  </div>
};

export {
  TopNerdsInfo,
  TopNerdsInfo2,
  TopNerdsInfo3,
  TopNerdsInfoImg,
  TopNerdsInfoClp3
}
