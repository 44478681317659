import React from 'react';
import classnames from 'classnames';

import './index.css';
import {NavLink} from "react-router-dom";

const Hnav = (props) => <div className={classnames('hnav-desk', props.className)}>{props.children}</div> ;

export const Hanv1Theme = (props) => {
  return <Hnav className="hanv-desk_1">{props.children}</Hnav>
};
export const Hanv2Theme = (props) => {
  return <Hnav className="hanv-desk_2">{props.children}</Hnav>
};
export const Hanv3Theme = (props) => {
  return <Hnav className={classnames('hanv-desk_3', {[`hanv-desk_3_${props.theme}`]: props.theme,})}>{props.children}</Hnav>
};
export const Hanv4Theme = (props) => {
  return <Hnav className="hanv-desk_clp3">{props.children}</Hnav>
};
export const HnavExt = ({ list }) => {
  return (
    <nav className="hanv-desk_ext">
      {list.map((item, key) => {
        return(
          <NavLink to={item.link} className='hnav__link' key={`hnav_${key}`}>{item.name}</NavLink>
        )
      })}
    </nav>
  )
};
