import React from 'react'
import Scroll from 'react-scroll'

const HeroBuilder = Hero => class extends React.Component {

  onRef = (el) => {
    this.heroRef = el
  }

  handleOnClickScrollTo = () => {
    if (this.heroRef) {
      Scroll.animateScroll.scrollTo(this.heroRef.clientHeight)
    }
  }

  render() {
    return (
      <Hero
        handleOnClickScrollTo={this.handleOnClickScrollTo}
        onRef={this.onRef}
        {...this.props}
      />
    )
  }
}

export default HeroBuilder

