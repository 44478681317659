import React, { Fragment, useEffect } from 'react';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router-dom';

import tracker from './lrtracker';

import { saveQueryToStorage } from './utils';
import CookiePopUp from './components/CookiePopUp';

import satellite from './satellites';
import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import HealthCheck from './base_components/HealthCheck';

const RouteWrapper = ({ component: Component, meta = {}, ...rest }) => {
  const { pageCfg } = rest;

  if (pageCfg.redirectTo) {
    return <Redirect to={pageCfg.redirectTo} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          {' '}
          <ScrollToTopOnMount />
          <Helmet
            meta={[
              { name: 'keywords', content: meta.keywords },
              { name: 'description', content: meta.description },
              (meta.robots && { name: 'robots', content: meta.robots }) || {},
              (meta.ogTitle && {
                property: 'og:title',
                content: meta.ogTitle,
              }) ||
                {},
              (meta.ogImage && {
                property: 'og:image',
                content: meta.ogImage,
              }) ||
                {},
            ]}
          >
            <title>{meta.title}</title>
          </Helmet>
          <Component {...props} pageCfg={rest.pageCfg} />
          <CookiePopUp />
        </Fragment>
      )}
    />
  );
};

/**
 * @param {Object} props
 */

const App = () => {
  useEffect(() => {
    saveQueryToStorage();

    tracker.metrics.trackLocation().then(() => {
      tracker.metrics.trackGoogleClientId();
    });
  }, []);

  return (
    <div className="page">
      <Helmet meta={[{ name: 'viewport', content: satellite.viewport }]} />

      <Switch>
        <Route path={'/healthcheck'} component={HealthCheck} />

        {satellite.routes.map((item, key) => {
          return <RouteWrapper {...item} key={`route-wrapper-${key}`} />;
        })}

        <Route
          path="/p/:key"
          render={(props) => {
            const { match } = props;
            const { key } = match.params;

            if (key) {
              window.location = `https://nerdifyit.paymentsgateway.co/p/${key}`;
            }

            return null;
          }}
        />
      </Switch>
    </div>
  );
};
export default App;
