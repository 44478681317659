import React, { Component } from 'react';
import classnames from "classnames";
import LazyLoad from 'react-lazyload';

export const PressItem = (props) => {
  const { children } = props;

  return (
    <div className='press__item'>
      <LazyLoad>
      {children}
      </LazyLoad>
    </div>
  )
};

export const PressList = (props) => {
  const { children } = props;

  return (
    <div className='press__list'>
      {children}
    </div>
  )
};

export default class Press extends Component {
  render () {
    const {className, children} = this.props;

    return (
      <div className={classnames('press', className)}>
        <div className='holder'>
          {children}
        </div>
      </div>
    )
  }
}
