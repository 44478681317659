import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { getScrollbarWidth } from './../../../../utils';
import './scrollbar-wrap.css';


class ScrollbarWrap extends Component {

  constructor(props) {
    super(props);

    this.state = { };

    this.onUpdate = this.onUpdate.bind(this);
  }

  renderTrack() {
    return <div className="scrollbar-wrap__track" />;
  }
  
  renderThumb() {
    return <div className="scrollbar-wrap__thumb" />;
  }

  onUpdate(values) {
    const ref = this.scrollRef;
    const scrollBarWidth = getScrollbarWidth();
    ref.view.style.marginRight = `${-scrollBarWidth}px`;
    ref.view.style.marginBottom = `${-scrollBarWidth}px`;
    ref.view.style.minHeight = `${scrollBarWidth}px`;
  }

  render() {
    const { children, className, style, maxHeight, autoHide } = this.props;

    return (
      <Scrollbars
        ref={(el) => {
          this.scrollRef = el;
          if (typeof this.props.scrollRef === 'function' ) {
            return this.props.scrollRef(el);
          }
        }}
        className={className}
        style={style}
        autoHide={autoHide}
        autoHeight
        autoHeightMax={maxHeight}
        renderThumbVertical={this.renderThumb}
        renderTrackVertical={this.renderTrack}
        onUpdate={this.onUpdate}
        onScrollFrame={this.handleScrollFrame}
        hideTracksWhenNotNeeded
      >
        {children}
      </Scrollbars>
    );
  }
}

export default ScrollbarWrap;
