import React from 'react';
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

export default (props) => {
  const { className, children } = props;

  return <div className={classnames('section', className)}>{children}</div>
}

export const SectionItems = (props) => {
  const { items } = props;

  return  [...new Array(items)].map((item, key) => {
    return <LazyLoad key={`shape_${key}`}>
      <div className={`shape shape_${key}`} />
    </LazyLoad>
  })
};
