import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import './index.css';

import { REACT_APP_CONTACT_EMAIL } from './../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage au>
      <Holder className="holder_1">
        <h1 className="h1">Cookie Policy</h1>

        <div className="textPage-item">
          <h3 className="h3">Cookie Policy: An Overview</h3>
          <p className="p">
            The purpose of this document is to officially inform anyone who is
            visiting thenerdify.com for any purpose that we may use cookies and
            other identification technologies for our services. In addition to
            using these on our website, we may also use these technologies in
            our apps, messaging utilities, tools, and other services. The
            objective of this policy is to provide our users and visitors with
            the information they need about how their information is used.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Definition of Terms: Cookies</h3>
          <p className="p">
            Cookies are small files that are downloaded to your phones, laptop,
            or any device you use when you visit thenerdify.com. By downloading
            these files, we are able to track data about your use of our
            website, apps, and other utilities. By using cookies, we are able to
            remember visitors when they return to our website multiple times.
            Let’s learn about the cookies we use:{' '}
          </p>
          <ol className="textPage-ol">
            <li>
              <strong>First-Party Cookies:</strong> the cookies we set are first-party cookies. We use them for information retention when you exit our website. These cookies retain your information, such as your log-in details.
            </li>
            <li>
              <strong>Third-Party Cookies:</strong> they are downloaded and
              installed by other entities. thenerdify.com does not control
              these.
            </li>
            <li>
              <strong>Session Cookies:</strong> they contain current browsing
              sessions on our website. These cookies are deleted once you close
              your browser.
            </li>
            <li>
              <strong>Persistent Cookies:</strong> these cookies remain
              installed even after you exit our website. For instance,
              thenerdify.com can remember your preferences.
            </li>
          </ol>
        </div>

        <div className="textPage-item">
          <h3 className="h3">
            Definition of Terms: Identification Technologies
          </h3>
          <ol className="textPage-ol">
            <li>
              <strong>Pixel Tags/Pixels/Beacons:</strong> these are small files
              that are designed to work with cookies. These are used to
              recognize returning users. They can identify the User’s device
              type, IP address, and many more. They also collect anonymous data
              on their on-site behavior.
            </li>
            <li>
              <strong>Local Storage: </strong> where information from browsers,
              third parties, and websites is stored.
            </li>
            <li>
              <strong>Software Development Kits (SDKs):</strong> they operate in
              a mobile application and function like cookies and pixels. The
              partners of developers can acquire various information from their
              users through SDKs.
            </li>
          </ol>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Consenting to Use of Cookies</h3>
          <p className="p">
            As a User of thenerdify.com, you have the right to reject or accept
            cookies. However, our services cannot function properly without the
            use of cookies. In the event that you do not want to provide this
            consent, you may adjust your settings to block cookies and other
            technologies by clicking “Help” or “Preferences” on your browser.
            However, some third parties may allow you to reject cookies through
            a link.
          </p>
          <p className="p">
            Please note that rejecting or removing cookies from your browser may
            not affect third-party flash cookies that we may possibly employ for
            our services. Refer to the links below for more information:
          </p>
          <ul className="ul">
            <li>
              <a
                className="a"
                href="http://allaboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.allaboutcookies.org
              </a>
            </li>
            <li>
              <a
                className="a"
                href="http://youronlinechoices.eu"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.youronlinechoices.eu
              </a>
            </li>
          </ul>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Cookies: Types and Purposes</h3>
          <p className="p">
            We would like to present you a table of the cookies and our reasons
            for their usage:
          </p>
          <table className="table p">
            <thead>
              <tr>
                <th width="17%">Type of Cookie</th>
                <th>Purpose</th>
                <th width="27%">Who Serves (for example)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Type of Cookie">Localization</td>
                <td data-label="Purpose">
                  It means that our Users will see a “localized” version of our
                  website. What do we mean? For example, it means that we can
                  set the language of our website to your native tongue if the
                  cookie containing your information detected your location
                </td>
                <td data-label="Who Serves (for example)">
                  <ul className="ul">
                    <li>thenerdify.com</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td data-label="Type of Cookie">
                  Website features and Services
                </td>
                <td data-label="Purpose">
                  We deliver our services through this cookie. That’s also one
                  of the reasons why the website appears to “remember” you when
                  you log into your account. It also provides you with
                  customized content and experiences, social plugins, and
                  suggestions.
                </td>
                <td data-label="Who Serves (for example)">
                  <ul className="ul">
                    <li>thenerdify.com</li>
                    <li>Facebook</li>
                    <li>Twitter</li>
                    <li>Google</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td data-label="Type of Cookie">Analytics and research</td>
                <td data-label="Purpose">
                  It functions to help improve the services we deliver to you.
                  Like any other website, we can analyze how you use and
                  interact with our website. Simply put, this is to study your
                  online behavior and experience in thenerdify.com .
                </td>
                <td data-label="Who Serves (for example)">
                  <ul className="ul">
                    <li>Google</li>
                    <li>MixPanel</li>
                    <li>Yandex</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td data-label="Type of Cookie">Advertising</td>
                <td data-label="Purpose">
                  We show you relevant ads that may pique your interest once you
                  visit thenerdify.com website. Our partners may also show you
                  ads from other websites. This originates from the cookies
                  thenerdify.com and our partners collected. The cookie also
                  functions to study the effectiveness of our marketing
                  campaigns/strategies and track our performance. Want to learn
                  more about behavior-tracking ads or opt out from them? Feel
                  free to consult www.aboutads.info/choices if you live in the
                  United States. Alternatively, if you live outside of the said
                  country, you can refer to{' '}
                  <a
                    className="a"
                    href="http://youronlinechoices.eu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://youronlinechoices.eu/
                  </a>
                  .
                </td>
                <td data-label="Who Serves (for example)">
                  <ul className="ul">
                    <li>Microsoft</li>
                    <li>Yahoo</li>
                    <li>Bing</li>
                    <li>Twitter</li>
                    <li>MixPanel</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="textPage-item">
          <h3 className="h3">Feedback</h3>
          <p className="p">
            The aforementioned paragraphs detail our use of these technologies.
            Specifically, we detail how and why we use them. It is our hope that
            this document will provide sufficient information. However, we
            invite anyone with further questions to contact us at{' '}
            <a
              className="a"
              href={`mailto:${REACT_APP_CONTACT_EMAIL}`}
              rel="noopener noreferrer"
            >
              {REACT_APP_CONTACT_EMAIL}
            </a>
            .
          </p>
        </div>
      </Holder>
    </TextPage>
  </LocalesPage>
);
