import React from 'react';
import { NavLink } from 'react-router-dom';

import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import { REACT_APP_PP_EMAIL } from './../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage au>
      <Holder className="holder_1">
        <h1 className="h1">Privacy Policy</h1>

        <div className="textPage-item">
          <h3 className="h3">
            1. Introduction to thenerdify.com's Privacy Policy
          </h3>
          <p className="p">
            Our privacy policy tells you how we collect and process data received from you on our website. We update our privacy policy because we understand that privacy is important in guaranteeing a safe and comfortable user experience. This policy will be enforced starting on October 15, 2021. Please read the document to understand our views and practices regarding your personal data. <br />
            Last updated: October 15, 2021
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">2. Thenerdify.com's Information Collection</h3>
          <p className="p">
            We store your phone number, as it is useful to provide the services.
            However, declining to do so may not grant you access to our
            services.
          </p>
          <h4 className="h4">2.1. Types of Data We Collect</h4>
          <p className="p">2.1.1. The information you directly shared</p>
          <p className="p">
            When you log in to our website you will get to share some of your
            personal information such as phone number, email, degree, and
            credentials.
          </p>
          <p className="p">2.1.2. Information we automatically collect</p>
          <p className="p">
            Our server automatically registers your domain name and address, IP
            address, browser type, the visit length, and the pages you viewed.
            These are collected through our cookies. To learn more, please
            consult our{' '}
            <NavLink className="a" to="/cookies">
              Cookie Policy
            </NavLink>
            .
          </p>
          <p className="p">2.1.3. Information from third parties</p>
          <p className="p">
            Social media sites are typical examples of third parties. You may
            consent to share some of the information from your social media
            account to thenerdify.com.
          </p>
          <p className="p">2.1.4. Sensitive Information</p>
          <p className="p">The following should be shared at your own risk:</p>
          <ul className="ul">
            <li>Political alignment/philosophy.</li>
            <li>Religious beliefs/groups.</li>
            <li>Gender.</li>
            <li>Sexuality.</li>
            <li>Race and ethnicity.</li>
          </ul>
          <h4 className="h4">
            2.2. How does the Company Protect a Minor's Personal Information?
          </h4>
          <p className="p">
            A minor’s personal information is not collected and saved by our
            domains. In fact, our website should only be used by individuals
            aged 18+.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">3. How We Use Your Information</h3>
          <p className="p">
            We collect your information when you visit or register on our
            website. This information is used to personalize your experience,
            improve our website, and provide you with our virtual services.
          </p>
          <h4 className="h4">
            3.1. Using Your Information for the Website’s Operation
          </h4>
          <p className="p">
            Information is used to improve the performance of thenerdify.com’s
            website.
            <br />
            Your data is collected and used to process your transactions and
            conduct statistical analyses amongst our users.
            <br />
            A customer’s data is used to develop new products and services and
            monitor their behavior online.
            <br />
            It is to investigate cases of fraud and prevent our users from
            violating our policies.
          </p>
          <h4 className="h4">
            3.2. Using Your Information for Communication Purposes
          </h4>
          <ul className="ul">
            <li>To seek the User’s assistance/support;</li>
            <li>To answer any questions, you have about the platform;</li>
            <li>To send all the latest news and updates;</li>
            <li>To conduct and organize surveys.</li>
          </ul>
          <h4 className="h4">
            3.3. Using Your Information for Advertising Purposes
          </h4>
          <ul className="ul">
            <li>To provide personalized content to our Users;</li>
            <li>To promote content that may or may not interest you.</li>
          </ul>
          <h4 className="h4">3.4. Using Your Information for Legal Grounds</h4>
          <p className="p">
            All the information we collect (or even that you shared) is used
            legally in order to improve our customers’ user experience.
          </p>
          <ul className="ul">
            <li>
              Your phone numbers are collected and used to access our services;
            </li>
            <li>
              Another reason is we need them to prevent cases of fraud, keep our
              database safe, and protect our Users’ privacy and interests. This
              is to comply with the law.
            </li>
          </ul>
        </div>

        <div className="textPage-item">
          <h3 className="h3">
            {' '}
            4. Sharing Your Information and Affiliate Disclosure
          </h3>
          <p className="p">
            We have the right to share your information with third parties and local authorities in compliance with the law and to protect our legal rights.
          </p>
          <h4 className="h4">4.1. Service Providers</h4>
          <p className="p">
            Our third party partners may collect your personal data in
            compliance with the law and avail their services (Ex: payment and
            financial transactions).
          </p>
          <h4 className="h4">4.2. Other States</h4>
          <p className="p">
            We may have to send your information to other states with their
            local laws.
          </p>
          <h4 className="h4">4.3. Security</h4>
          <p className="p">
            Thenerdify.com’s employees are trained in handling your information.
            Only they can access your information, not anyone else. We screen
            our software and hardware for technical problems that may arise from
            security breaches.
          </p>
          <h4 className="h4">4.4. Affiliate Disclosure</h4>
          <p className="p">
            Thenerdify.com website is a member of the Nerdify Partnership
            Program. It means that the website has a right to earn an
            advertising fee in terms of a program. As a member of an affiliate
            program, thenerdify.com has customized links and/or contact forms,
            provided by thenerdify.com in the sake of tracking the referrals to
            their service. Cookies are used to track visits for the purposes of
            assigning advertising commission on sales.
          </p>
          <h4 className="h4">4.5. Personal Data and Partnership Program</h4>
          <p className="p">
            There are several affiliate contact forms on thenerdify.com by means
            of which the website may earn a commission upon submitting a contact
            form or making a purchase using the phone number you’ve provided in
            a form. When you decide to make a purchase, the final cost remains
            the same whether you use the affiliate contact form or text the
            Freelancer directly. By using the affiliate contact forms and links,
            you are supporting thenerdify.com.
          </p>
          <p className="p">
            You agree that by clicking the "Text me" button on a contact form
            you send a text-back request to thenerdify.com and thenerdify.com
            has a right to use your personal data (contact number) according to
            this Privacy Policy. Thenerdify.com shares personal data collected
            from you with thenerdify.com to provide customer service and assist
            you with your requests. Your personal data will not be shared with
            third parties other than specified on this Privacy Policy page.
          </p>
        </div>

        <div className="textPage-item">
          <h3 className="h3">
            5. What are Your Rights with Regard to Your Information?
          </h3>
          <p className="p">Your rights are to:</p>
          <ul className="ul">
            <li>Restrict Nerdify to process your information;</li>
            <li>
              Review and transfer all the information you shared on the
              platform;
            </li>
            <li>
              Write a complaint to your local Data Protection Authority (DPA) in
              the company’s use and storage of your personal information.
            </li>
            <li>
              Back out of marketing emails and cookies. Please review our{' '}
              <NavLink className="a" to="/cookies">
                Cookie Policy
              </NavLink>{' '}
              to learn more about cookies and our tracking technologies.
            </li>
          </ul>
          <p className="p">
            Do you want us to delete your data or review all the information we
            stored? Feel free to send us a message at{' '}
            <a
              className="a"
              href={`mailto:${REACT_APP_PP_EMAIL}`}
              rel="noopener noreferrer"
            >
              {REACT_APP_PP_EMAIL}
            </a>
            .
          </p>
        </div>
      </Holder>
    </TextPage>
  </LocalesPage>
);
