import React from 'react';
import classnames from 'classnames';

import './index.css';

const Intro = (props) => <div className={classnames('intro', props.className)}>{props.children}</div>;

export const Intro1 = (props) => <Intro className={'intro_1'} {...props} />;
export const Intro2 = (props) => <Intro className={'intro_2'} {...props} />;
export const Intro3 = ({ children, indent }) => <div className={classnames('intro_3', indent)}>{children}</div>;
export const Intro4 = ({ children, theme, indent }) => <div className={classnames('intro_4', {[`intro_4_${theme}`]: theme}, indent)}>{children}</div>;
export const Intro5 = ({ children, theme, indent }) => <div className={classnames('intro_5', {[`intro_5_${theme}`]: theme}, indent)}>{children}</div>;
