import React from 'react'
import FacebookButtonBase from '../../../../base_components/FacebookButton'
import Icons from './../Icons';
import classnames from 'classnames';

import './index.css'

export const Facebook = ({ children, className }) => (
  <div className={classnames('facebook', className)}>
    {children}
  </div>
)

export const Facebook1Theme = (props) => <Facebook {...props} className={'facebook_1'} />;
export const Facebook2Theme = (props) => <Facebook {...props} className={'facebook_2'} />;
export const Facebook3Theme = (props) => <Facebook {...props} className={'facebook_3'} />;
export const Facebook4Theme = (props) => <Facebook {...props} className={classnames('facebook_4', {[`facebook_4_${props.subTheme}`]: props.subTheme})} />;
export const Facebook5Theme = (props) => <Facebook {...props} className={classnames('facebook_5', {[`facebook_5_${props.subTheme}`]: props.subTheme})} />;

export const FacebookButton = ({ children, className = 'button__type2', ...rest }) => (
  <FacebookButtonBase
    {...rest}
    icons={Icons}
    className={className}
    iconName='fb-msg-icon'
    iconClassName='button-icon'
  >{children}</FacebookButtonBase>
);

export const FacebookButton11 = ({ children, className = 'button__type11', ...rest }) => ( // no icon
  <FacebookButtonBase
    {...rest}
    className={className}
  >{children}</FacebookButtonBase>
);

export const FacebookButton1 = ({ children, ...rest }) => (
  <FacebookButtonBase
    {...rest}
    icons={Icons}
    className={'button__type1'}
    iconName='fb-msg-icon'
    iconClassName='button-icon'
  >{children}</FacebookButtonBase>
);
