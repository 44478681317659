import React, { Component } from 'react';
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

import './index.css';

export class NerdsItem extends Component {
  render () {
    const {index} = this.props;

    return (
      <LazyLoad>
        <div
        className={`nerds__list-item nerds__list-item_${index}`}
        key={`nerds__list-item-${index}`} />
      </LazyLoad>
    )
  }
}

export class NerdsList extends Component {
  render () {
    const {children} = this.props;

    return (
      <div className='nerds__list'>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            index,
          });
        })}
      </div>
    )
  }
}

export default class Nerds extends Component {
  render () {
    const {className, children} = this.props;

    return (
      <div className={classnames('nerds', className)}>
        <div className='holder'>
          {children}
        </div>
      </div>
    )
  }
}
