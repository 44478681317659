import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
//import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './index.css';

const TIMEOUT = 500;

class BottomModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.bodyElement = document.getElementsByTagName("body")[0];
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      this.bodyElement.classList.add('fixed');
    }
    if (prevState.isOpen && !this.state.isOpen) {
      this.bodyElement.classList.remove('fixed');
    }
  }

  show() {
    this.setState({ isOpen: true });
  }

  hide() {
    this.setState({ isOpen: false });
  }

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="bottomModal">
        <CSSTransition
          in={isOpen}
          timeout={TIMEOUT}
          unmountOnExit
          classNames="backdrop"
        >
          <div className="backdrop" onClick={this.hide} />
        </CSSTransition>
        <CSSTransition
          in={isOpen}
          timeout={TIMEOUT}
          unmountOnExit
          classNames="modalContent"
        >
          <div className="modalContent">{children}</div>
        </CSSTransition>
      </div>
    );
  }
}

export default BottomModal;
