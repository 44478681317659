import React, { useEffect, useState } from 'react';
import baseIcons from './../Icons';
import { REACT_APP_FACEBOOK_PAGE } from './../../config';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { getLocalStorageQuery } from './../../utils';

import './index.css';

const FbButton = (props) => {
  const {
    facebookPage = REACT_APP_FACEBOOK_PAGE,
    children,
    icons,
    className,
    iconClassName,
    iconName,
    staticContext,
  } = props;

  const [ref, setRef] = useState(staticContext ? staticContext.ref : '');

  useEffect(() => {
    const query = getLocalStorageQuery();

    setRef(btoa(`landing=${query.path}&${query.search}`));
  }, [ref]);

  const Icons = icons || baseIcons;

  const link = ref ? `${facebookPage}?ref=${ref}` : `${facebookPage}`;

  return (
    <a
      className={classnames('button', className, {})}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {iconName && (
        <LazyLoad>
          <Icons className={iconClassName} iconName={iconName} />
        </LazyLoad>
      )}
      <span>{children}</span>
    </a>
  );
};

export default withRouter(FbButton);
