import React from 'react';
import classnames from 'classnames';
import baseIcons from './../Icons';

export default ({ className, type, children, iconName, iconClassName, icons, onClick, disabled, ...rest }) => {
  const Icons = icons || baseIcons
  return iconName 
    ? <button className={classnames('button', className)} 
        type={type || 'button'}
        onClick={onClick}
        disabled={disabled}
      >
        <Icons className={iconClassName} iconName={iconName} />
        <span>{children}</span>
      </button> 
    : <button className={classnames('button', className)} 
        type={type || 'button'}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button> 
}