import React from "react";
import Text from "../../../../base_components/Text";

export const HiwTitle = <Text>Fast & Easy. Already in your phone.</Text>;

export const HiwItems = [
  {
    img: 'hiw1.svg',
    message: 'Describe a task in your own words or send a photo of instructions'
  },
  {
    img: 'hiw2.svg',
    message: 'Get the best available Nerd assigned to help you with your task'
  },
  {
    img: 'hiw3.svg',
    message: 'Approve the price and make a secure payment through the platform'
  },
  {
    img: 'hiw20.svg',
    message: 'Get assistance and become one of thousands of happy Nerdify clients'
  }
];
