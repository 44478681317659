import React, {Component} from 'react';
import Icons from './../../../../base_components/Icons';
import servicesIcons from './img/icons.svg';

import './services.css';
import classnames from "classnames";

class Services extends Component {
  render () {
    const {className, children} = this.props;
    return (
      <div className={classnames('services-block', className)}>
        {children}
      </div>
    )
  }
}

export const ServicesIcon = (props) => {
  return <Icons icons={servicesIcons} className='svg-icon' iconName={props.iconName} />
};

export const ServicesImg = ({img, ...rest}) => <img {...rest} src={require(`./img/${img}`)} alt={''} />;

export const DocumentDownloadLink = ({ link, children, ...rest }) => <a {...rest} href={`pdf/${link}`}>
  {children}
</a>


export default Services
