import React from 'react';
import Icon from '../Icons';
import HeroBuilder from '../../../../base_components/HeroBuilder'

import './index.css'

const Hero = ({
  onRef,
  handleOnClickScrollTo,
  children
}) => (
    <div className="hero hero--1" ref={el => onRef(el)}>
      <div className="holder">
        {children}
        <div className="hero__scrollto" onClick={handleOnClickScrollTo} >
          <Icon
            iconName="scroll-down-arrow"
            className="icon-scroll-down-arrow"
          />
        </div>
      </div>
    </div>
  )

export default HeroBuilder(Hero)
