import React, {
  Fragment
} from 'react';
import { NavLink } from 'react-router-dom';

import {
  TextBackTheme1,
  TextBackTheme2,
  TextBackTheme3,
  TextBackTheme6,
  TextBackForm
} from '../../../../themes/nerdify/components/TextBack';

import { InputSelectLocales } from '../../../../themes/nerdify/components/Form/InputSelect';
import { Facebook2, Facebook5 } from '../../blocks/Facebook'

const AgreeItems = () => {
  return (
    <div className="textback__agree-items">
      <div className="textback__agree-item">Immediate Reply</div>
      <div className="textback__agree-item">Fully Confidential</div>
      <div className="textback__agree-item">No Spam</div>
    </div>
  )
}

const AgreeLinks1 = () => {
  return (
    <p className="p__tiny textback__agree">
      By clicking the button you agree to our <br className="mobile-only" />
      {' '}
      <NavLink className='link a__underline' to='/terms-and-conditions'>Terms</NavLink>
      {' '}and{' '}
      <NavLink className='link a__underline' to='/privacy-policy'>Privacy Policy</NavLink>
    </p>
  )
}

const AgreeLinks2 = () => {
  return (
    <p className="p__tiny textback__agree">
      By clicking these buttons you agree to our <br className="mobile-only" />
      {' '}
      <NavLink className='link a__underline' to='/terms-and-conditions'>Terms</NavLink>
      {' '}and{' '}
      <NavLink className='link a__underline' to='/privacy-policy'>Privacy Policy</NavLink>
    </p>
  )
}

const TextBackContent = () => (
  <Fragment>
    <p className="p textback__label">Just type your number and we'll text you back</p>
    <TextBackForm />
    <p className="p__tiny textback__agree">
      By clicking the Text Me button, you agree to our <br />
      {' '}
      <NavLink className='link a__underline' to='/terms-and-conditions'>Terms of Use</NavLink>,
      {' '}
      <NavLink className='link a__underline' to='/privacy-policy'>Privacy</NavLink>
      {' '} and {' '}
      <NavLink className='link a__underline' to='/cookies'>Cookie Policies</NavLink>.
    </p>
  </Fragment>
);

const TextBackWithLocales = (props) => {
  return (
    <TextBackForm
      {...props}
      renderSelectLocales={() => (
        <InputSelectLocales className="input-select__short" name="country" />
      )}
    />
  );
};

const TextBackContent2 = (props) => (
  <Fragment>
    <p className="p textback__label">We'll text you back in 5 sec to help ASAP</p>
    {TextBackWithLocales(props)}
    {AgreeItems()}
    {AgreeLinks1()}
  </Fragment>
);

const TextBackContent3 = (props) => (
  <Fragment>
    {TextBackWithLocales(props)}
    <Facebook2 subTheme={1} />
    {AgreeItems()}
    {AgreeLinks2()}
  </Fragment>
);


export const TextBack1 = (props) => <TextBackTheme1 {...props}>
  <TextBackContent {...props} />
</TextBackTheme1>;

export const TextBack2 = (props) => <TextBackTheme2 {...props}>
  <TextBackContent {...props} />
</TextBackTheme2>;

export const TextBack3 = (props) => <TextBackTheme3 {...props}>
  <TextBackContent2 {...props} />
</TextBackTheme3>;

export const TextBack4 = (props) => <TextBackTheme3 {...props}>
  <TextBackContent3 {...props} />
</TextBackTheme3>;

export const TextBack5 = (props) => (
  <TextBackTheme3 {...props}>
    {TextBackWithLocales(props)}
  </TextBackTheme3>
);

export const TextBack6 = (props) => (
  <TextBackTheme6 {...props}>
    <p className="p textback__label">We'll text you back in 10 sec to help ASAP</p>
    {TextBackWithLocales (props)}
    <Facebook5 />
    {AgreeItems()}
    {AgreeLinks2()}
  </TextBackTheme6>
);

export const TextBack7 = (props) => (
  <TextBackTheme6 {...props}>
    {TextBackWithLocales (props)}
    <Facebook5 />
    {AgreeItems()}
    {AgreeLinks2()}
  </TextBackTheme6>
);
